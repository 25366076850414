const formatterDate = new Intl.DateTimeFormat('ru-RU')
const formatterDateTime = new Intl.DateTimeFormat('ru-RU', {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
})

// DD.MM.YYYY
function formatDate (date) {
  return formatterDate.format(new Date(date))
}

// DD.MM.YYYY HH:mm
function formatDateTime (date) {
  return formatterDateTime.format(new Date(date)).replace(',', '')
}

// YYYY-MM-DD
function formatDateForServer (date) {
  return new Date(date).toLocaleDateString('en-ca')
}

// YYYY-MM-DD HH:mm
function formatDateTimeForServer (date) {
  const newDate = new Date(date)

  const YMD = newDate.toISOString().split('T')[0]
  // HH:mm:ss
  const time = newDate.toTimeString().split(' ')[0].split(':')

  return `${YMD} ${time[0]}:${time[1]}`
}

export {
  formatDate,
  formatDateTime,
  formatDateForServer,
  formatDateTimeForServer
}
