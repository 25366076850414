<template>
    <div :class="['form__control', classForm, getError ? 'error' : '']">
      <input
        :class="[classInput, getError ? 'error' : '', IsLengthInput ? 'active' : '']"
        :id="id"
        :name="name"
        :type="type"

        :value="modelValue"
        :disabled="disabled"
        v-i-mask-directive.trim="mask"

        @input="maskInput"
        @accept="onAccept"
        @blur="setBlur"
      >
<!--      <div class="effect-field"/>-->

      <label
        v-if="classInput !== 'input-border-all'"
        :for="id"
        :class="['label-valid', getError ? 'error' : 'success']">
      </label>

      <label
        :class="['label--placeholder', IsLengthInput ? 'active' : '']"
        :for="id">
        {{ placeholder }}
      </label>

      <div v-if="errors?.length || serverErrors?.length">
        <small class="error-small" v-if="errors[0]?.$message">{{ errors[0].$message }}</small>
        <small class="error-small" v-if="serverErrors?.length && !errors[0]?.length">{{ serverErrors[0] }}</small>
      </div>
      <small v-if="description && !errors?.length" class="description">{{ description }}</small>
    </div>
  </template>

<script>
import { reactive, computed } from 'vue'
import { IMaskDirective } from 'vue-imask'
import { fieldProps } from '@/mixin/props'
import { inputRangeMaxMin } from '@/utils/utils'

export default {
  directives: {
    IMaskDirective
  },
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    classInput: {
      type: [Array, String],
      default: 'input'
    },
    classLabel: {
      type: [Array, String],
      default: 'label'
    },
    classForm: {
      type: [Array, String]
    },
    modelValue: {
      type: String
    },
    serverErrors: {
      type: Array,
      required: false
    },
    maskCustom: {
      type: String,
      required: false,
      default: '+0(000)000-00-00-0'
    },
    maskType: {
      type: Boolean,
      required: false,
      default: false
    },
    isRange: {
      type: Boolean,
      required: false,
      default: false
    },
    range: {
      type: Object,
      required: false
    },
    isMaskedValue: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'tel'
    }
  },
  mixins: [fieldProps],
  setup (props, { emit }) {
    const mask = reactive({
      mask: props.maskCustom,
      lazy: props.maskType
    })

    const getError = computed(() => {
      return props.errors?.length || props.serverErrors?.length
    })

    const IsLengthInput = computed(() => {
      if (props.modelValue === null) return 0
      return String(props.modelValue)?.length
    })

    const onAccept = (e) => {
      if (props.isMaskedValue) {
        emit('update:modelValue', e.detail.value)
      } else {
        emit('update:modelValue', e.detail.unmaskedValue)
      }
      emit('input', props.name)
      if (props.serverErrors?.length) props.serverErrors.length = 0
    }

    const maskInput = (e) => {
      if (props.isRange) {
        inputRangeMaxMin(e.target, props.range)
      }
    }

    const setBlur = () => {
      emit('blur', props.name)
    }

    return {
      maskInput,
      IsLengthInput,
      mask,
      onAccept,
      getError,
      setBlur
    }
  }
}
</script>
