<template>
  <header :class="['header', DONT_MOVE]" v-header-anim>
    <div class="container">
      <div class="header__inner">
        <VIcon
          class="logo logo--header"
          width="122"
          height="34"
          icon-name="Logo"
          @click="goMain"
        />

        <TheNav />
      </div>
    </div>
  </header>
</template>

<script>
import VIcon from '@/components/ui/icon/VIcon'
import TheNav from '@/components/header/TheNav'
import { goMain } from '@/utils/goRoute'
import { headerAnim, DONT_MOVE } from '@/directives/anim.header'

export default {
  directives: {
    headerAnim
  },
  setup () {
    return {
      DONT_MOVE,
      goMain
    }
  },
  components: {
    VIcon,
    TheNav
  }
}

</script>
